export const formConfList = [
	{
		label: '是否需要复审',
		prop: '1',
		type: 'radio',
		options: [
			{
				label: '是',
				value: 1
			},
			{
				label: '否',
				value: 2
			}
		]
	},
	{
		label: '数据权限',
		prop: '2',
		type: 'radio',
		options: [
			{
				label: '不分类',
				value: 1
			},
			{
				label: '按类目',
				value: 2
			}
		]
	},
	{
		label: '自动寄样设置',
		prop: '2',
		type: 'radio',
		options: [
			{
				label: '手动发起',
				value: 1
			},
			{
				label: '报名发起',
				value: 2
			},
			{
				label: '初审通过发起',
				value: 3
			},
			{
				label: '复审通过发起',
				value: 4
			}
		]
	},
	{
		label: '寄样要求',
		prop: '111',
		type: 'textarea',
		rows: 8
	},
	{
		label: '敏感词配置',
		prop: '1131',
		type: 'textarea',
		rows: 8
	}
]