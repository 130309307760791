<template>
	<div>
		<e-form :formConfList="formConfList" />
		<el-button type="primary" class="ml100">保存</el-button>
	</div>
</template>

<script>
import {formConfList} from '@data/system/jobprocess'
export default {
	name: 'Jobprocess',
	data() {
		return {
			formConfList
		}
	}
}
</script>